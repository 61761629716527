import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import userContext from '../context/UserContext';

const Expenses = () => {
    const [expenses, setExpenses] = useState([]);
    const [filteredExpenses, setFilteredExpenses] = useState([]);
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const { token } = useContext(userContext);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchExpenses = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/expenses`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setExpenses(response.data);
                setFilteredExpenses(response.data); // Initialize filtered expenses
            } catch (err) {
                console.error("Error fetching expenses:", err);
            }
        };
        fetchExpenses();
    }, [token]);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/expenses/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setExpenses(expenses.filter(expense => expense.expense_id !== id));
            setFilteredExpenses(filteredExpenses.filter(expense => expense.expense_id !== id)); // Update filtered expenses
        } catch (err) {
            console.error("Error deleting expense:", err);
        }
    };

    const handleFilter = () => {
        const filtered = expenses.filter(expense => {
            const expenseMonth = expense.month.toString();
            const expenseYear = expense.year.toString();
            return (month ? expenseMonth === month : true) && (year ? expenseYear === year : true);
        });
        setFilteredExpenses(filtered);
    };

    return (
        <div className="card col-md-12 mx-auto">
            <div className="card-header">
                <h2 className="text-center">Expenses</h2>
                <button className="btn btn-success" onClick={() => navigate('/dashboard/expenseadd')}>Add New Expense</button>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className='col-md-3'>

                    <select className="form-select" value={month} onChange={(e) => setMonth(e.target.value)}>
                        <option value="">Select Month</option>
                        {[...Array(12).keys()].map(m => (
                            <option key={m + 1} value={m + 1}>{new Date(0, m).toLocaleString('default', { month: 'long' })}</option>
                        ))}
                    </select>
                        </div>
                        <div className='col-md-3'>

                    <select className="form-select" value={year} onChange={(e) => setYear(e.target.value)}>
                        <option value="">Select Year</option>
                        {Array.from(new Set(expenses.map(expense => expense.year))).map((yr) => (
                            <option key={yr} value={yr}>{yr}</option>
                        ))}
                    </select>
                        </div>
                        <div className='col-md-3'>

                    <button className="btn btn-primary ml-2" onClick={handleFilter}>Filter</button>
                        </div>
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>M / Y</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredExpenses.map(expense => (
                            <tr key={expense.expense_id}>
                                <td>{expense.detail}</td>
                                <td>{expense.amount}</td>
                                <td>{new Date(expense.date).toLocaleDateString()}</td>
                                <td>{expense.month} / {expense.year}</td>
                                <td>
                                    <button className="btn btn-warning" onClick={() => navigate(`/dashboard/expenseedit/${expense.expense_id}`)}>Edit</button>
                                    <button className="btn btn-danger ml-1" onClick={() => handleDelete(expense.expense_id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Expenses;
