import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import userContext from '../context/UserContext';

const ExpenseEdit = () => {
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(true);
    const { expense_id } = useParams();
    const navigate = useNavigate();
    const { token } = useContext(userContext);

    useEffect(() => {
        const fetchExpense = async () => {
            try {
                console.log("Fetching expense with ID:", expense_id); // Debugging line
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/expenses/${expense_id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const { detail, date, amount } = response.data;
                setDescription(detail);
                const formattedDate = new Date(date).toISOString().split('T')[0];
                setDate(formattedDate);
                setAmount(amount);
            } catch (err) {
                console.error("Error fetching expense:", err);
            } finally {
                setLoading(false);
            }
        };
        
        fetchExpense();
    }, [expense_id, token]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.patch(`${process.env.REACT_APP_API_URL}/expenses/${expense_id}`, {
                description,
                date,
                amount
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            navigate('/dashboard/expenses');
        } catch (err) {
            console.error("Error updating expense:", err);
        }
    };

    // Return loading state or form
    if (loading) {
        return <div>Loading...</div>; // You may want to replace this with a better loading indicator
    }

    return (
        <div className="card col-md-8 mx-auto">
            <div className="card-header">
                <h2 className="text-center">Edit Expense</h2>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label><b>Description</b></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Expense Description..."
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label><b>Date</b></label>
                        <input
                            type="date"
                            className="form-control"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label><b>Amount</b></label>
                        <input
                            type="number" // Changed to number input for better UX
                            className="form-control"
                            placeholder="Expense Amount..."
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-warning mt-3">Update Expense</button>
                    <button type="button" className="btn btn-secondary mt-3 ml-1" onClick={() => navigate(`/dashboard/expenses`)}>Back</button>
                </form>
            </div>
        </div>
    );
};

export default ExpenseEdit;
