import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import userContext from '../context/UserContext';

const ExpenseAdd = () => {
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [amount, setAmount] = useState('');
    const navigate = useNavigate();
    const { token } = useContext(userContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/expenses/add`, { description, date, amount }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            navigate('/dashboard/expenses');
        } catch (err) {
            console.error("Error adding expense:", err);
        }
    };

    return (
        <div className="card col-md-8 mx-auto">
            <div className="card-header">
                <h2 className="text-center">Add Expense</h2>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label><b>Description</b></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Expense Description..."
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label><b>Date</b></label>
                        <input
                            type="date"
                            className="form-control"
                            onChange={(e) => setDate(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label><b>Amount</b></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Expense Amount..."
                            onChange={(e) => setAmount(e.target.value)}
                            required
                        />
                    </div>
                    <button className="btn btn-primary mt-3">Add Expense</button>
                    <button className="btn btn-secondary mt-3 ml-1" onClick={() => navigate(`/dashboard/expenses`)}>Back</button>
                </form>
            </div>
        </div>
    );
};

export default ExpenseAdd;
